// extracted by mini-css-extract-plugin
export var border1 = "cZ_h2";
export var border2 = "cZ_h3";
export var border3 = "cZ_h4";
export var border4 = "cZ_h5";
export var container = "cZ_f";
export var dark = "cZ_cY";
export var large = "cZ_ck";
export var ldsRing = "cZ_h6";
export var light = "cZ_cW";
export var medium = "cZ_cl";
export var primaryPressed = "cZ_h7";
export var xlarge = "cZ_h1";