import React, { ReactNode } from "react"

import Paragraph from "../../design-system/paragraph"
import Title from "../../design-system/title"
import { classNames } from "../../utils/class-names"
import * as styles from "./index.module.scss"

type Props = {
  title: ReactNode
  subtitle?: ReactNode
  className?: string
  children?: ReactNode
  textColor?: "white"
  titleElement?: "h1" | "h2"
}

function SectionTitleContainer({
  title,
  subtitle,
  className,
  children,
  textColor,
  titleElement = "h2",
}: Props) {
  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.textContainer}>
        <Title variant="2" textColor={textColor} as={titleElement}>
          {title}
        </Title>
        {subtitle && (
          <Paragraph textColor={textColor} variant="1">
            {subtitle}
          </Paragraph>
        )}
      </div>
      {children}
    </div>
  )
}

export default SectionTitleContainer
