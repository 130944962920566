// extracted by mini-css-extract-plugin
export var centeredTitle = "cX_hV";
export var childrenContainer = "cX_hX";
export var container = "cX_f";
export var dark = "cX_cY";
export var innerContainer = "cX_cL";
export var left = "cX_cC";
export var light = "cX_cW";
export var noBottomPadding = "cX_hQ";
export var noRectangle = "cX_hT";
export var noTopPadding = "cX_hR";
export var overflowHidden = "cX_hS";
export var right = "cX_cD";
export var subtitle = "cX_k";
export var tan = "cX_cy";
export var textContainer = "cX_b3";
export var textContainerCustomWidth = "cX_hW";