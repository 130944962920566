import React from "react"

import { classNames } from "../../utils/class-names"
import * as styles from "./index.module.scss"

export interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  variant: "1" | "2" | "3"
  as?: "p" | "span"
  textColor?: "white" | "tertiary"
}

function Paragraph({
  as = "p",
  variant,
  className,
  textColor,
  ...rest
}: Props) {
  const Element = as
  const variantClassName = styles[`variant${variant}`]

  return (
    <Element
      className={classNames(
        styles.paragraph,
        variantClassName,
        textColor === "white" && styles.paragraphWhite,
        textColor === "tertiary" && styles.paragraphTertiary,
        className,
      )}
      {...rest}
    />
  )
}

export default Paragraph
