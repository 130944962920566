import React, {
  ChangeEvent,
  ComponentType,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react"

import useClickOutside from "../../utils/hooks/use-click-outside"
import FeatherIcon from "../feather-icon"
import Input from "../input"
import * as styles from "./index.module.scss"
import SelectionBox from "./selection-box"
import { isValueOption, Option, ValueOption } from "./selection-box/types"
import useKeyboardNavigation from "./selection-box/use-keyboard-navigation"

export interface BaseProps {
  id?: string
  className?: string
  options: readonly Option[]
  blurOnSelect?: boolean
  keepOpen?: boolean
  onOptionSelect?: (option: ValueOption) => void
  onClear?: () => void
  renderOption?: ComponentType<ValueOption>
  selectionBoxClassName?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  displayValue?: string
  value?: string
  clearable?: boolean
  small?: boolean
  "data-testid"?: string
}

type AllowedInputProps = Omit<
  React.ComponentProps<typeof Input>,
  keyof BaseProps | "setInputValue"
>
type Props = BaseProps & AllowedInputProps

const Dropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      options,
      onClear,
      onOptionSelect,
      onChange,
      keepOpen,
      blurOnSelect,
      renderOption,
      selectionBoxClassName,
      placeholder,
      value,
      displayValue,
      clearable,
      small,
      ...inputProps
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [selectionBoxIsOpen, setSelectionBoxIsOpen] = useState(false)
    const closeSelectionBox = useCallback(
      () => setTimeout(() => setSelectionBoxIsOpen(false)),
      [],
    )

    const innerRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLDivElement)
    useClickOutside(innerRef, closeSelectionBox)

    const handleOptionSelect = useCallback(
      (option: ValueOption) => {
        if (!keepOpen) {
          if (blurOnSelect) {
            inputRef.current?.blur()
          } else {
            inputRef.current?.focus()
          }
          closeSelectionBox()
        }
        onOptionSelect?.(option)
      },
      [keepOpen, onOptionSelect, closeSelectionBox, blurOnSelect],
    )

    const { focusedItemIndex } = useKeyboardNavigation({
      options,
      onEnterPress: handleOptionSelect,
      boxIsOpen: selectionBoxIsOpen,
      setBoxIsOpen: setSelectionBoxIsOpen,
      value,
      ref: innerRef,
      inputRef,
    })

    const handleInputClick = useCallback(() => {
      if (selectionBoxIsOpen && inputRef.current === document.activeElement) {
        closeSelectionBox()
      } else {
        setSelectionBoxIsOpen(true)
      }
    }, [selectionBoxIsOpen, closeSelectionBox])

    const handleInputChange = useCallback<
      React.EventHandler<ChangeEvent<HTMLInputElement>>
    >(
      e => {
        const { value: targetValue } = e.target
        onChange?.(e)
        if (
          !selectionBoxIsOpen &&
          !options
            .filter(isValueOption)
            .some(option => option.label === targetValue)
        ) {
          setSelectionBoxIsOpen(true)
        }
      },
      [onChange, options, setSelectionBoxIsOpen, selectionBoxIsOpen],
    )

    const handleClear = useCallback(() => {
      const clearEvent = {
        target: { value: "" },
      } as ChangeEvent<HTMLInputElement>
      onClear?.()
      handleInputChange(clearEvent)
      setSelectionBoxIsOpen(false)
    }, [handleInputChange, onClear])

    const selectedItem = options
      .filter(isValueOption)
      .find(option => option.value === value)
    return (
      <div
        className={`${className} ${styles.dropdown} ${
          small ? styles.small : ""
        } 
        ${selectionBoxIsOpen && styles.open} ${
          clearable &&
          (displayValue ?? selectedItem?.label) &&
          styles.hasClearIcon
        }`}
        ref={innerRef}
      >
        {clearable && (displayValue ?? selectedItem?.label) && (
          <FeatherIcon
            type="x-circle"
            className={styles.clearIcon}
            onClick={handleClear}
            data-testid={`${inputProps["data-testid"] ?? ""}-clear-icon`}
          />
        )}
        <Input
          value={displayValue ?? selectedItem?.label ?? ""}
          onMouseDown={handleInputClick}
          onFocus={() => setSelectionBoxIsOpen(true)}
          aria-expanded={selectionBoxIsOpen}
          role="combobox"
          ref={inputRef}
          onChange={handleInputChange}
          readOnly={!onChange}
          autoComplete="off"
          placeholder={placeholder}
          variant={small ? "xmedium" : "large"}
          {...inputProps}
        />
        {selectionBoxIsOpen && (
          <SelectionBox
            options={options}
            onClick={handleOptionSelect}
            renderOption={renderOption}
            focusedItemIndex={focusedItemIndex}
            className={selectionBoxClassName}
            inputRef={inputRef}
            selectedItem={selectedItem}
          />
        )}
      </div>
    )
  },
)

Dropdown.displayName = "Dropdown"

export default Dropdown
