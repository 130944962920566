// extracted by mini-css-extract-plugin
export var button = "Q_v";
export var children = "Q_cp";
export var disabled = "Q_cg";
export var fullWidth = "Q_cn";
export var iconWrapper = "Q_cr";
export var large = "Q_ck";
export var link = "Q_b9";
export var loading = "Q_cf";
export var loadingContainer = "Q_cq";
export var medium = "Q_cl";
export var primary = "Q_cd";
export var secondary = "Q_ch";
export var small = "Q_cm";
export var tertiary = "Q_cj";