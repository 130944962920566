import { navigate as gatsbyNavigate } from "gatsby"

type NavigateFunction = (
  to: string | number,
  options?: {
    state?: Record<string, unknown>
    replace?: boolean
  },
) => Promise<void>

// Gatsby's navigate function since v. 5.14.0 has a bug in it's types where it doesn't allow
// a string, even though it's a valid type. TODO: Remove this once it's fixed.
export const navigate: NavigateFunction = (to, options) => {
  const typedGatsbyNavigate = gatsbyNavigate as NavigateFunction
  return typedGatsbyNavigate(to, options)
}
