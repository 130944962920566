// This file was automatically generated by the script update-experiment-list.ts
// DO NOT EDIT MANUALLY

// To add an experiment to this file, add it to statsig. Use tags to indicate which pages it should apply to
// To create global experiments, or experiments that apply to subpaths use the blob "*" syntax:
// Examples:
//   - "/**" = all pages,
//   - "/demo/**" = all subpaths of "/demo",
//   - "/demo/*" = 1 level deep subpaths of demo (i.e.: matches "/demo/one" but not "/demo/one/two")

const MOCK_EXPERIMENT_THREE_IN_SAME_PAGE = {
  id: "mock_experiment_three_in_same_page",
  description: "",
  hypothesis:
    "Experiment will work as expected even if there are three on the same page",
  status: "setup",
  variants: [
    { id: "control", isControl: true, stringParameter: "control" },
    { id: "test", isControl: false, stringParameter: "test" },
  ],
} as const

const MOCK_EXPERIMENT_TWO_IN_SAME_PAGE = {
  id: "mock_experiment_two_in_same_page",
  description: "",
  hypothesis: "Multiple experiments on the same page will work.",
  status: "setup",
  variants: [
    { id: "control", isControl: true, stringParameter: "control" },
    { id: "test", isControl: false, stringParameter: "test" },
  ],
} as const

const MOCK_ABOVE_FOLD_SINGLE_PAGE = {
  id: "mock_above_fold_single_page",
  description: "",
  hypothesis:
    "This is a mock experiment meant to check if experiments for a single page that affect above the fold behave as expected.",
  status: "setup",
  variants: [
    { id: "control", isControl: true, stringParameter: "control" },
    { id: "test", isControl: false, stringParameter: "test" },
  ],
} as const

export const experimentMatcherData = {
  "/mock-experiment": [
    MOCK_EXPERIMENT_THREE_IN_SAME_PAGE,
    MOCK_EXPERIMENT_TWO_IN_SAME_PAGE,
    MOCK_ABOVE_FOLD_SINGLE_PAGE,
  ],
} as const

export const uniqueExperiments = [
  MOCK_EXPERIMENT_THREE_IN_SAME_PAGE,
  MOCK_EXPERIMENT_TWO_IN_SAME_PAGE,
  MOCK_ABOVE_FOLD_SINGLE_PAGE,
] as const
