import type { EventProperties } from "@segment/analytics-next"
import sbjs from "sourcebuster"
import Cookies from "universal-cookie"

import { EXPERIMENT_UID_COOKIE } from "../experimentation/constants"
import * as schema from "./analytics/schema"
import { AnalyticsWindow } from "./types"
import { isInternalRoute } from "./url-params"

declare let window: AnalyticsWindow

export const { eventNames } = schema
export { EventProperties }
export const DEFAULT_DOMAIN = "tremendous.com"

const attributionIdKeys = [
  "fbclid", // facebook
  "gclid", // google
  "li_fat_id", // linkedin
  "msclkid", // bing
] as const

type AttributionIdKey = (typeof attributionIdKeys)[number]
const getCookieKey = (idKey: AttributionIdKey) => `trem_${idKey}`

function setAttributionCookiesFromUrlParams() {
  const url = window.location.search
  const urlParams = new URLSearchParams(url)
  const cookies = new Cookies()
  const today = new Date()
  const expires = new Date(today.setDate(today.getDate() + 90))

  const domain =
    process.env.GATSBY_ENV === "production"
      ? DEFAULT_DOMAIN
      : window.location.hostname

  attributionIdKeys.forEach(idKey => {
    const valueFromUrlParam = urlParams.get(idKey)

    if (valueFromUrlParam) {
      const cookieKey = getCookieKey(idKey)
      cookies.set(cookieKey, valueFromUrlParam, {
        expires,
        domain,
        path: "/",
      })
    }
  })
}

const analyticsNotInitializedErrorHandler = (error: Error, message: string) => {
  if (
    error.message ===
    `Cannot read properties of undefined (reading '${message}')`
  ) {
    // eslint-disable-next-line no-console
    console.error(
      `Tried to call window.analytics.${message} before analytics was initialized`,
    )
  } else {
    throw error
  }
}

export const pageViewed = (name: string) => {
  sbjs.init({
    domain: DEFAULT_DOMAIN,
  })

  try {
    window.analytics.page(
      name,
      {
        sbjs_current_traffic_type: sbjs.get.current.typ,
        sbjs_current_utm_campaign: sbjs.get.current.cmp,
        sbjs_current_utm_medium: sbjs.get.current.mdm,
        sbjs_current_utm_term: sbjs.get.current.trm,
        sbjs_current_referer: sbjs.get.current_add.rf,
        width: window.innerWidth,
      },
      {
        campaign: {
          name: sbjs.get.current.cmp,
          source: sbjs.get.current.src,
          medium: sbjs.get.current.mdm,
          term: sbjs.get.current.trm,
          content: sbjs.get.current.cnt,
        },
      },
    )

    setAttributionCookiesFromUrlParams()
  } catch (e) {
    analyticsNotInitializedErrorHandler(e as Error, "page")
  }
}

export const track = async (message: string, data?: EventProperties) => {
  sbjs.init({
    domain: DEFAULT_DOMAIN,
  })
  const cookies = new Cookies()
  const statsigUserId = cookies.get(EXPERIMENT_UID_COOKIE)
  const campaign = {
    name: sbjs.get.current.cmp,
    source: sbjs.get.current.src,
    medium: sbjs.get.current.mdm,
    term: sbjs.get.current.trm,
    content: sbjs.get.current.cnt,
  }

  try {
    window.analytics.track(message, { ...data, statsigUserId }, { campaign })
  } catch (e) {
    analyticsNotInitializedErrorHandler(e as Error, "track")
  }
}

export const buttonClicked = (data: EventProperties) => {
  track(eventNames.buttonClicked, {
    ...data,
    pageName: window.location.pathname,
  })
}

export const itemClicked = (data: EventProperties) => {
  track(eventNames.itemClicked, {
    ...data,
    pageName: window.location.pathname,
  })
}

export const pageLoaded = () => {
  track(eventNames.pageLoaded, {
    pageName: window.location.pathname,
    width: window.innerWidth,
  })
}

type FormSubmissionTrackingData<T> = {
  formName: string
  formStep?: number
  formData: T
  errorMessage?: string
}

export function trackFormSubmissionResult<T>(
  data: FormSubmissionTrackingData<T>,
) {
  if (isInternalRoute()) {
    return
  }
  const { errorMessage } = data
  track(
    errorMessage
      ? eventNames.formSubmissionErrored
      : eventNames.formSubmissionSucceeded,
    {
      ...data,
      pageName: window.location.pathname,
    },
  )
}

export const attributionTraits = () => {
  sbjs.init({
    domain: DEFAULT_DOMAIN,
  })

  const cookies = new Cookies()

  const attributionIds = attributionIdKeys.reduce(
    (acc, idKey) => {
      const cookieKey = getCookieKey(idKey)
      acc[idKey] = cookies.get(cookieKey)
      return acc
    },
    {} as Record<AttributionIdKey, string | null>,
  )

  return {
    ...attributionIds,

    // Sourcebuster.js
    traffic_type: sbjs.get.first.typ,
    utm_campaign: sbjs.get.first.cmp,
    utm_medium: sbjs.get.first.mdm,
    utm_source: sbjs.get.first.src,
    utm_content: sbjs.get.first.cnt,
    utm_term: sbjs.get.first.trm,
    referer: sbjs.get.first_add.rf,
    landing_page: sbjs.get.first_add.ep,
    ipAddress: sbjs.get.udata.uip,

    // Google Analytics
    cid: cookies.get("_ga"),

    // Segment
    user_public_token: cookies.get("ajs_user_id"),
    anonymousid: cookies.get("ajs_anonymous_id"),

    // Hubspot
    hubspot_utk: cookies.get("hubspotutk"),
  }
}

type VideoEventBaseProperties = {
  sessionId: string
  title: string
  totalLength: number
  position?: number
}

type VideoPlaybackEventProperties = VideoEventBaseProperties & {
  contentAssetId: string
  sound: number
}

type VideoContentEventProperties = VideoEventBaseProperties & {
  assetId: string
}

export const videoPlaybackStarted = (data: VideoPlaybackEventProperties) => {
  const newData = { ...data, pageName: window.location.pathname }
  track(eventNames.videoPlaybackStarted, newData)
}

export const videoContentStarted = (data: VideoContentEventProperties) => {
  const newData = { ...data, pageName: window.location.pathname }
  track(eventNames.videoContentStarted, newData)
}

export const videoContentEnded = (data: VideoContentEventProperties) => {
  const newData = { ...data, pageName: window.location.pathname }
  track(eventNames.videoContentEnded, newData)
}

export const videoContentInterrupted = (data: VideoContentEventProperties) => {
  const newData = { ...data, pageName: window.location.pathname }
  track(eventNames.videoContentInterrupted, newData)
}
