import React from "react"

import { classNames } from "../../utils/class-names"
import { HtmlHeadingTagType } from "../title"
import * as styles from "./index.module.scss"

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  variant: "1" | "2" | "3" | "4" | "5" | "6"
  as: HtmlHeadingTagType
  textColor?: "white"
}

function Header({ as, variant, className, textColor, ...rest }: Props) {
  const Element = as
  const variantClassName = styles[`variant${variant}`]

  return (
    <Element
      className={classNames(
        styles.header,
        variantClassName,
        textColor === "white" && styles.headerWhite,
        className,
      )}
      {...rest}
    />
  )
}

export default Header
